import { combineReducers } from "@reduxjs/toolkit";
import content from "./contentSlice";
import formStepState from "./formStepStateSlice";

const reducer = combineReducers({
  content,
  formStepState,
});

export default reducer;
