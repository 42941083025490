import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";

export const selectProfessionalFormStepState = ({ dashboard }: RootState) =>
  dashboard.professional.settings.formStepState;

interface IInitialState {
  activeStepIndex: number;
}

const initialState: IInitialState = {
  activeStepIndex: 0,
};

const formStepStateSlice = createSlice({
  name: "dashboard/professional/settings/formStep/state",
  initialState,
  reducers: {
    setActiveStepIndex: (state, action: PayloadAction<IInitialState["activeStepIndex"]>) => {
      state.activeStepIndex = action.payload;
    },
  },
});

export const { setActiveStepIndex } = formStepStateSlice.actions;

export default formStepStateSlice.reducer;
