import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";

export const selectAuthProfessionalRegisterState = ({ auth }: RootState) =>
  auth.register.professional.state;
export const selectAuthProfessionalRegisterActiveStep = createSelector(
  (state: RootState) => state.auth.register.professional,
  ({ content: { steps, profileCompletionSteps }, state: { activeSection, activeStepId } }) =>
    (activeSection === "steps"
      ? steps
      : activeSection === "fullProfileCompletion"
        ? profileCompletionSteps
        : []
    ).find((s) => s.stepId === activeStepId) ?? steps[0],
);
export const selectAuthProfessionalRegisterActiveSteps = createSelector(
  (state: RootState) => state.auth.register.professional,
  ({
    content: { steps, profileCompletionSteps },
    state: { activeSection, activeStepId, prevStepIds },
  }) =>
    (activeSection === "steps"
      ? steps
      : activeSection === "fullProfileCompletion"
        ? profileCompletionSteps
        : []
    ).filter(
      (s) => !prevStepIds.length || prevStepIds.includes(s.stepId) || s.stepId === activeStepId,
    ),
);

interface IInitialState {
  activeSection:
    | "steps"
    | "verifyEmail"
    | "profileCompletionChoice"
    | "success"
    | "fullProfileCompletion"
    | "profileCreation";
  activeStepId: string;
  prevStepIds: string[];
  registerFormValues: { email: string; password: string };
}

const initialState: IInitialState = {
  activeSection: "steps",
  activeStepId: "",
  prevStepIds: [],
  registerFormValues: { email: "", password: "" },
};

const registerStateSlice = createSlice({
  name: "auth/register/professional/state",
  initialState,
  reducers: {
    setActiveSection: (state, action: PayloadAction<IInitialState["activeSection"]>) => {
      state.activeSection = action.payload;
    },
    setActiveStepId: (state, { payload }: PayloadAction<IInitialState["activeStepId"]>) => {
      if (payload < state.activeStepId) {
        const index = state.prevStepIds.indexOf(payload);
        state.prevStepIds = index !== -1 ? state.prevStepIds.slice(0, index) : [];
      }
      state.activeStepId = payload;
    },
    addToPrevStepIds: (state, action: PayloadAction<IInitialState["prevStepIds"]>) => {
      state.prevStepIds.push(...action.payload);
    },
    setRegisterFormValues: (state, action: PayloadAction<IInitialState["registerFormValues"]>) => {
      state.registerFormValues = action.payload;
    },
  },
});

export const { setActiveSection, setActiveStepId, addToPrevStepIds, setRegisterFormValues } =
  registerStateSlice.actions;

export default registerStateSlice.reducer;
