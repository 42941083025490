import { ErrorResult, getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IProfessionalProfileContent } from "models/dashboard/professional/profile";
import { IUser } from "models/dashboard/shared";
import { formPopulate } from "models/form";
import { imagePopulateWithFormats } from "models/shared";
import { transformForm, transformResponse } from "utils/format";

export const getProfessionalProfileContentReq = () => {
  return axiosInstance
    .get(`/api/professional-profile`, {
      params: {
        populate: {
          profileSection: {
            populate: {
              completionFields: formPopulate["form"]["populate"]["fields"],
              items: true,
            },
          },
          aboutSection: {
            populate: { values: true },
          },
          detailsSection: {
            populate: { values: true },
          },
          reviewsSection: {
            populate: "*",
          },
          previousWorkSection: {
            populate: "*",
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IProfessionalProfileContent>)
    .then((data) =>
      transformForm(data, [{ path: "profileSection", fieldsKey: "completionFields" }]),
    )
    .catch(getError);
};

export async function getUserProfile(): Promise<IUser | ErrorResult> {
  return axiosInstance
    .get<IUser>("/api/users/me", {
      params: {
        populate: {
          role: { populate: "*" },
          profilePicture: imagePopulateWithFormats,
          buyerSeller: { populate: "*" },
          professional: {
            populate: {
              reviews: {
                populate: {
                  images: imagePopulateWithFormats,
                  author: { populate: { profilePicture: imagePopulateWithFormats } },
                },
              },
              previousWork: {
                populate: {
                  images: imagePopulateWithFormats,
                },
              },
            },
          },
        },
      },
      withCredentials: true,
    })
    .then(({ data }) => data)
    .catch(getError);
}

export async function getIsSubscribedNewsletter() {
  return axiosInstance
    .get<boolean>("/api/email/is-subscribed-newsletter", {
      withCredentials: true,
    })
    .then(({ data }) => data)
    .catch(getError);
}
