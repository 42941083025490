import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IBuyerSellerLoginContent } from "models/auth";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerLoginContentReq } from "rest-api/auth/buyer-owner-login";

export const getConsumerLoginContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("auth/login/consumer/content/getConsumerLoginContent", async (_, { rejectWithValue }) => {
  const result = await getConsumerLoginContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectAuthConsumerLoginContent = ({ auth }: RootState) => auth.login.consumer.content;

const initialState: IBuyerSellerLoginContent = {
  title: "",
  form: { fields: [], submitButtonText: "" },
  socialLogins: [],
  switchToRegisterText: "",
  verifyEmailSection: {
    title: "",
    content: "",
    form: { fields: [], submitButtonText: "" },
    resendCodeContent: "",
    resendCodeButtonText: "",
  },
};

const contentSlice = createSlice({
  name: "auth/login/consumer/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerLoginContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IBuyerSellerLoginContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
