import { ISelectOption } from "models/form";
import { IImage } from "models/shared";

export interface IAvatarUser {
  firstName: string;
  lastName: string;
  profilePicture?: IImage;
}

export const userKeys = [
  "firstName",
  "lastName",
  "profilePicture",
  "phone",
  "locations",
  "suburbs",
  "professional.businessName",
  "professional.job",
  "professional.certificate",
  "professional.publicLiabilityInsurance",
  "professional.publicLiabilityExpirationDate",
  "professional.publicLiabilityPolicyNumber",
  "professional.abn",
  "buyerSeller.goal",
  "buyerSeller.propertyType",
  "buyerSeller.timeframe",
] as const;

export interface IReview {
  rating: number;
  content: string;
  images: IImage[];
  reviewDate: string;
  author: Pick<IUser, "firstName" | "lastName" | "profilePicture">;
}

export interface IPreviousWork {
  title: string;
  content: string;
  images?: IImage[];
}

export interface IUser {
  id: string;
  questionnaireComplete: boolean;
  role: { name: "Consumer" | "Professional" | null };
  confirmed: boolean;
  email: string;
  firstName: string;
  lastName: string;
  profilePicture?: IImage;
  phone: string;
  locations: string[];
  suburbs: string[];
  newsletterSubscribed?: boolean;
  invoiceDue?: boolean;
  professional?: {
    businessName: string;
    job: string;
    certificate: string;
    publicLiabilityInsurance: string;
    publicLiabilityExpirationDate: string;
    publicLiabilityPolicyNumber: string;
    abn: string;
    reviews: IReview[];
    previousWork: IPreviousWork[];
  };
  buyerSeller?: {
    goal: string[];
    propertyType: string;
    timeframe: string;
  };
  commercialEmailsUnsubscribed: boolean;
}

export interface IUserTransformed
  extends Omit<IUser, "locations" | "suburbs" | "professional" | "buyerSeller"> {
  locations: ISelectOption[];
  suburbs: ISelectOption[];
  professional?: Omit<
    NonNullable<IUser["professional"]>,
    "job" | "certificate" | "publicLiabilityInsurance"
  > & {
    job: ISelectOption;
    certificate: ISelectOption;
    publicLiabilityInsurance: ISelectOption;
  };
  buyerSeller?: {
    goal: ISelectOption;
    propertyType: ISelectOption;
    timeframe: ISelectOption;
  };
}

export interface IDashboardLayout {
  footer: {
    content: string;
    copyright: string;
  };
}

export interface IHeaderSection {
  title: string;
  content?: string;
  icon: IImage;
}
