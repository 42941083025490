import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IBuyerSellerRegisterContent } from "models/auth";
import { formPopulate } from "models/form";
import { imagePopulate } from "models/shared";
import { transformForm, transformResponse } from "utils/format";

export const getConsumerRegisterContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-register`, {
      params: {
        populate: {
          questionnaireSteps: {
            populate: {
              header: { populate: { icon: imagePopulate } },
              forms: formPopulate["form"],
              nextStepGroups: { populate: { or: { populate: "*" }, and: { populate: "*" } } },
            },
          },
          registerSection: {
            populate: { socialLogins: { populate: { icon: imagePopulate } } },
          },
          emailRegisterSection: { populate: formPopulate },
          verifyEmailSection: { populate: formPopulate },
          socialRegisterSection: { populate: formPopulate },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IBuyerSellerRegisterContent>)
    .then((data) => {
      for (let s of data.questionnaireSteps) {
        for (let form of s.forms) {
          transformForm({ form }, ["form"]);
        }
      }
      return data;
    })
    .then((data) =>
      transformForm(data, [
        "emailRegisterSection.form",
        "verifyEmailSection.form",
        "socialRegisterSection.form",
      ]),
    )
    .catch(getError);
};
